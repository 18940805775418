import { template as template_21bc3fbeca974f00bedf80fb0b436473 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_21bc3fbeca974f00bedf80fb0b436473(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
