import { template as template_0292ed7fe52144be973c1b64f5d84d8e } from "@ember/template-compiler";
const FKControlMenuContainer = template_0292ed7fe52144be973c1b64f5d84d8e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
